import React, { useEffect, useState } from "react";
import { Modal, Button, Input, Tooltip } from "antd";
import axios from "axios";
import { PlusOutlined, InfoCircleOutlined } from "@ant-design/icons";
import add from "../assests/AiOutlinePlus.svg";
import moment from "moment";
const Sidenav = ({ uId }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSuggestionModalVisible, setIsSuggestionModalVisible] =
    useState(false);
  const [questions, setQuestions] = useState({
    input: "",
    displaySuggestion: " ",
  });

  const [notes, setNotes] = useState([]);
  const patientId = uId;

  const loadClinicalNotes = async (patientId) => {
    const url = "https://ins02.truliacare.com/notesapi/notes";
    const body = {
      intent: "get_all_notes",
      payload: {
        entityid: patientId.toString(),
      },
    };
    try {
      const response = await axios.post(url, body);
      return response.data.payload;
    } catch (error) {
      console.error("Error loading clinical notes:", error);
      throw error;
    }
  };

  const addClinicalNotes = async (patientId, noteDescription) => {
    const url = "https://ins02.truliacare.com/notesapi/notes";
    const body = {
      intent: "create_new_note",
      payload: {
        entityid: patientId.toString(),
        text: noteDescription,
      },
    };
    try {
      const response = await axios.post(url, body);
      return response.data.payload;
    } catch (error) {
      console.error("Error adding clinical notes:", error);
      throw error;
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };
  const suggestionModal = () => {
    setIsSuggestionModalVisible(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addClinicalNotes(patientId, questions.displaySuggestion);
      const updatedNotes = await loadClinicalNotes(patientId);
      setNotes(updatedNotes.map((note) => ({ ...note, expanded: false })));
      setQuestions({ input: "", displaySuggestion: " " });
    } catch (error) {
      console.error("Error adding and fetching notes:", error);
    }
    setIsModalVisible(!isModalVisible);
  };
  const handleSuggestionClick = (question) => {
    setQuestions((prevState) => ({
      ...prevState,
      displaySuggestion: question,
    }));
    setQuestions((prevState) => {
      const newState = {
        ...prevState,
        displaySuggestion: question,
      };
      // console.log(newState);
      return newState;
    });
    setIsSuggestionModalVisible(false);
  };
  const toggleNoteExpand = (index) => {
    const updatedNotes = [...notes];
    updatedNotes[index].expanded = !updatedNotes[index].expanded;
    setNotes(updatedNotes);
  };

  const suggestedQuestions = [
    "Patient was in her wheelchair, not responsive, eyes closed, and was not responding to my name. Breathing was light and slow. After 10 or 15 min, she opened her eyes. Patient was thirsty. An aide came in and found that the patient's oxygen tank on the back of the chair was empty. Aide had to call a nurse for help in getting it switched over/filled. Nurse checked the patient's oxygen level and it was 96%. The patient and I had a long nice visit; the patient shared stories about her family. The Patient's cat's name is LilBits. I could not find any wet cat food (the can in his dish was empty and dry beyond just a day's worth) . I found some dry food so I fed the cat and gave it fresh water and cleaned the litter box. Patient loves old westerns on TV and playing bingo.",
    "Pt experienced intermittent agitation and was being supported with medications. Issues with her sister continued to be a source of combativeness, but she responded well to pet therapy, particularly enjoying visits from Lily the pet. Family involvement remained limited, with her son rarely answering the phone but showing support during in-person meetings. Pt’s engagement with music therapy decreased, but it continued to be a part of her care plan. Her new cushion from the specialty med group was given to her today.",
  ];

  const formatDate = (timestamp) => {
    return moment.unix(timestamp).format("DD/MM/YYYY, h:mm A");
  };

  const sorted = notes.sort(
    (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
  );

  useEffect(() => {
    const fetchNotes = async () => {
      try {
        const notesData = await loadClinicalNotes(patientId);
        setNotes(notesData.map((note) => ({ ...note, expanded: false })));
      } catch (error) {
        console.error("Error fetching notes:", error);
      }
    };

    fetchNotes();
  }, [patientId]);
  return (
    <>
      <div className="sidenav">
        <div className="notes-header">
          <h3 style={{ cursor: "pointer", marginLeft: "10px" }}>Note</h3>
          <div>
            <Tooltip title="Note">
              <img
                src={add}
                style={{ cursor: "pointer", marginRight: "10px" }}
                onClick={showModal}
              />
            </Tooltip>
          </div>
        </div>
        <hr
          style={{
            marginLeft: "15px",
            marginRight: "15px",
            marginTop: "0rem",
            background: "#d4d4d4",
            opacity: "0.25",
          }}
        />
        <div className="noteTitle  ">
          {sorted.map((note, index) => (
            <div key={index} style={{ marginBottom: "10px" }}>
              <p className="timestampText">{formatDate(note.timestamp)}</p>
              <p className="notesText">
                {note.expanded
                  ? note.text
                  : note.text.length > 200
                  ? `${note.text.substring(0, 200)}...`
                  : note.text}
                {note.text.length > 200 && (
                  <span style={{ marginLeft: "5px" }}>
                    <span
                      style={{
                        cursor: "pointer",
                        color: "#1967d3",
                        fontSize: "12px",
                      }}
                      onClick={() => toggleNoteExpand(index)}
                    >
                      {note.expanded ? "Read Less" : "Read More"}
                    </span>
                  </span>
                )}
              </p>
              <hr
                style={{
                  margin: "4px",
                  height: "1px",
                  background: "#d4d4d4",
                  opacity: "0.25",
                }}
              />
            </div>
          ))}
        </div>

        <Modal
          title="Add Note"
          open={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={false}
          centered
        >
          <form onSubmit={handleSubmit}>
            <Input.TextArea
              rows={10}
              value={questions.displaySuggestion}
              onChange={(e) =>
                setQuestions((prevState) => ({
                  ...prevState,
                  input: e.target.value,
                  displaySuggestion: e.target.value,
                }))
              }
              placeholder="Enter your note here"
            />
            <div
              style={{
                textAlign: "end",
                marginTop: "1.2rem",
                marginBottom: "-1.2rem",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <InfoCircleOutlined
                onClick={suggestionModal}
                style={{
                  fontSize: "24px",
                  color: "#1967d3",
                  marginRight: "10px",
                  textAlign: "center",
                }}
              />
              <Button
                key="submit"
                htmlType="submit"
                style={{ backgroundColor: "#1967d3", color: "#ffffff" }}
                onClick={handleCancel}
              >
                Add Note
              </Button>
            </div>
          </form>
        </Modal>
      </div>
      <Modal
        title="Suggested Questions"
        open={isSuggestionModalVisible}
        onCancel={() => setIsSuggestionModalVisible(false)}
        footer={null}
      >
        <ul className="suggested-questions">
          {suggestedQuestions.map((question, index) => (
            <li
              key={index}
              style={{ cursor: "pointer" }}
              onClick={() => handleSuggestionClick(question)}
              className="notes"
            >
              {question.expanded
                ? question
                : question.length > 200
                ? `${question.substring(0, 200)}...`
                : question}
              {/* {question.length > 200 && (
                <span style={{ marginLeft: "5px" }}>
                  <span
                    style={{
                      cursor: "pointer",
                      color: "#1967d3",
                      fontSize: "12px",
                    }}
                    onClick={() => toggleNoteExpand(index)}
                  >
                    {question.expanded ? "Read Less" : "Read More"}
                  </span>
                </span>
              )} */}
            </li>
          ))}
        </ul>
      </Modal>
    </>
  );
};

export default Sidenav;
