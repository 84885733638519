import React, { useEffect, useState } from "react";
import axios from "axios";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Modal, Spin, Tooltip } from "antd";
import yesIcon from "../assests/Tick.svg";
import noIcon from "../assests/Pending.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment } from "@fortawesome/free-solid-svg-icons";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import careGiver from "../assests/AiOutlineFileSearch.svg";
import sync from "../assests/AiOutlineSync.svg";
import reload from "../assests/AiOutlineReload.svg";

import ChatBot from "./ChatBot";
import moment from "moment";

const MainPage = ({ uId }) => {
  const entityId = uId;
  const [nonClinicalSummary, setNonClinicalSummary] = useState("");
  const [clinicalSummary, setClinicalSummary] = useState("");
  const [tasks, setTasks] = useState([]);
  const [view, setView] = useState("lifeJourney");
  const [loadingSummaries, setLoadingSummaries] = useState(true);
  const [loadingTasks, setLoadingTasks] = useState(true);
  const [tags, setTags] = useState([]);
  const patientId = uId;
  const [showChatbot, setShowChatbot] = useState(false); // State for chatbot visibility
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [notesData, setNotesData] = useState([]);
  const [tagData, setTagData] = useState([]);
  const [tagModalData, setTagModalData] = useState([]);

  const showModal = (value) => {
    // Here we get only value
    const tagKeys = Object.values(value);

    // Here we filter the tag from tag data
    const filterTags = Object.keys(tagData).filter((tag) => tag === tagKeys[0]);

    // Here we extract the key of that particular tag
    const filteredTagId = tagData[filterTags];

    // Here we find that id and return the note in notesData
    const tagNote = notesData.find((note) => note.noteid === filteredTagId);

    setTagModalData(tagNote);
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const loadClinicalNotes = async (patientId) => {
    const url = "https://ins02.truliacare.com/notesapi/notes";
    const body = {
      intent: "get_all_notes",
      payload: {
        entityid: patientId,
      },
    };
    try {
      const response = await axios.post(url, body);
      setNotesData(response.data.payload);
    } catch (error) {
      console.error("Error loading clinical notes:", error);
      throw error;
    }
  };

  const fetchSummaries = async () => {
    try {
      const summaryResponse = await axios.post(
        "https://ins02.truliacare.com/notesapi/notes",
        {
          intent: "get_summaries_of_notes",
          payload: {
            entityid: entityId,
          },
        }
      );

      const summaryData = summaryResponse.data.payload;
      setClinicalSummary(
        summaryData.clinical_summary || "No Clinical Summary Found"
      );
      setNonClinicalSummary(
        summaryData.non_clinical_summary || "No Life Journey Found"
      );
    } catch (error) {
      console.error("Error fetching summary", error);
    } finally {
      setLoadingSummaries(false);
    }
  };

  const fetchTasks = async () => {
    try {
      const tasksResponse = await axios.post(
        "https://ins02.truliacare.com/notesapi/notes",
        {
          intent: "get_tasks_for_entity",
          payload: {
            entityid: entityId.toString(),
          },
        }
      );

      const tasksData = tasksResponse.data.payload || [];
      setTasks(tasksData);
    } catch (error) {
      console.error("Error fetching tasks", error);
    } finally {
      setLoadingTasks(false);
    }
  };
  const fetchTags = async () => {
    const url = "https://ins02.truliacare.com/notesapi/notes";
    const body = {
      intent: "get_tags_of_entity",
      payload: {
        entityid: patientId.toString(),
      },
    };
    try {
      const response = await axios.post(url, body);
      let tagsData = response.data.payload;
      setTagData(tagsData);
      if (typeof tagsData != "object") {
        tagsData = {};
      }
      const tagsArray = Object.keys(tagsData).map((key) => ({ key }));
      // sorting tags based alphabetically
      tagsArray.sort((a, b) => {
        const nameA = a.key;
        const nameB = b.key;
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      setTags(tagsArray);
      // console.log(tags);
    } catch (error) {
      console.error("Error loading tags:", error);
    }
  };
  const priorityOrder = { isCompleted: 1, Low: 2, Medium: 3, High: 4 };

  const handleReload = () => {
    setLoadingSummaries(true);
    setLoadingTasks(true);
    fetchSummaries();
    fetchTasks();
    fetchTags();
  };

  const handleReset = async () => {
    try {
      const response = await axios.get(
        "https://ins02.truliacare.com/notesapi/reset_demo_api"
      );
      window.location.reload();
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    handleReload();
    loadClinicalNotes(entityId);
  }, [view]);

  const columns = [
    {
      title: "Tasks",
      dataIndex: "text",
      key: "text",
    },
    {
      title: "Assigned To",
      dataIndex: "assignedTo",
      key: "assignedTo",
    },
    {
      title: "Priority",
      key: "criticality",
      render: (record) => (
        <span
          className={
            record.metadata_?.criticality === "High"
              ? "high-criticality"
              : record.metadata_?.criticality === "Medium"
              ? "medium-criticality"
              : "low-criticality"
          }
        >
          {record.metadata_?.criticality}
        </span>
      ),
    },
    {
      title: "Completed",
      dataIndex: "isCompleted",
      key: "isCompleted",
      render: (record) => (
        <span>
          <Tooltip title={record.isCompleted ? "Completed" : "Pending"}>
            <img
              src={record.isCompleted ? yesIcon : noIcon}
              style={{ width: 80, height: 20 }}
            />
          </Tooltip>
        </span>
      ),
    },
  ];

  // sorting task based on the criticality
  tasks.sort((a, b) => {
    if (a.isCompleted === true && b.isCompleted === false) {
      return 1;
    }
    if (a.isCompleted === false && b.isCompleted === true) {
      return -1;
    }
    return (
      priorityOrder[b.metadata_.criticality] -
      priorityOrder[a.metadata_.criticality]
    );
  });
  const formatDate = (timestamp) => {
    return moment.unix(timestamp).format("DD/MM/YYYY, h:mm A");
  };
  return (
    <>
      <div className="mainpage">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div className="button-group ">
            <button
              className={view === "lifeJourney" ? "active" : ""}
              onClick={() => setView("lifeJourney")}
            >
              Life Journey
            </button>
            {/* <button
            className={view === "clinicalSummary" ? "active" : ""}
            onClick={() => setView("clinicalSummary")}
          >
          Clinical Summary
          </button> */}
            <button
              className={view === "tasks" ? "active" : ""}
              onClick={() => setView("tasks")}
            >
              Tasks
            </button>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Tooltip title="Care-Giver Assistant">
              <a
                href="https://ins07.truliacare.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={careGiver}
                  alt=""
                  style={{ marginLeft: "1em", cursor: "pointer" }}
                />
              </a>
            </Tooltip>
            <Tooltip title="Click To load Summary">
              <img
                src={sync}
                alt="..refresh"
                onClick={handleReload}
                style={{ marginLeft: "1em", cursor: "pointer" }}
              />
            </Tooltip>
            <Tooltip title="Reset">
              <img
                src={reload}
                alt=""
                onClick={handleReset}
                style={{ marginLeft: "1em", cursor: "pointer" }}
              />
            </Tooltip>
          </div>
        </div>
        <div className="cust-border">
          <div className="content">
            {view === "lifeJourney" && (
              <>
                {loadingSummaries ? (
                  <div className="spinner-container">
                    <Spin size="small" />
                  </div>
                ) : (
                  <div>
                    <ReactMarkdown remarkPlugins={[remarkGfm]}>
                      {nonClinicalSummary}
                    </ReactMarkdown>
                  </div>
                )}
              </>
            )}
            {/* {view === "clinicalSummary" && (
            <>
            {loadingSummaries ? (
              <div className="spinner-container">
              <Spin size="small" />
              </div>
              ) : (
                <ReactMarkdown remarkPlugins={[remarkGfm]}>
                {clinicalSummary}
                </ReactMarkdown>
                )}
                </>
                )} */}
            <>
              {view === "tasks" && (
                <div className="task-container">
                  <TableContainer component={Paper}>
                    <Table
                      sx={{ minWidth: 650 }}
                      aria-label="task table"
                      className="task-table"
                    >
                      <TableHead className="task-table-head">
                        <TableRow className="task-table-row">
                          {columns.map((column) => (
                            <TableCell key={column.key} className="table-cell">
                              {column.title}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {tasks.length === 0 ? (
                          <TableRow>
                            <TableCell
                              colSpan={columns.length}
                              align="center"
                              className="table-cell"
                            >
                              No Task Found
                            </TableCell>
                          </TableRow>
                        ) : (
                          tasks.map((task) => (
                            <TableRow
                              key={task.key}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              {columns.map((column) => (
                                <TableCell
                                  key={column.key}
                                  className="table-cell"
                                >
                                  {column.render
                                    ? column.render(task)
                                    : task[column.dataIndex]}
                                </TableCell>
                              ))}
                            </TableRow>
                          ))
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              )}
            </>
          </div>
        </div>
        {view === "lifeJourney" && (
          <>
            <h2>Tags</h2>
            <hr style={{ marginBottom: "1em", opacity: "50%" }} />
            <div className="container_patient_tags">
              <div className="tags_container">
                {tags.map((tag, index) =>
                  tag ? (
                    <div
                      key={index}
                      style={{ marginBottom: "10px", cursor: "pointer" }}
                      onClick={() => showModal(tag)}
                    >
                      <span className="tags">{tag.key}</span>
                    </div>
                  ) : (
                    "No Tags Found"
                  )
                )}
              </div>
            </div>
          </>
        )}
        <div
          className="chatbot-icon"
          onClick={() => setShowChatbot(!showChatbot)}
        >
          <FontAwesomeIcon icon={faComment} />
        </div>
        {/* Chatbot */}
        {showChatbot && <ChatBot onClose={() => setShowChatbot(false)} />}
      </div>
      <Modal
        title="Note"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width={600}
        centered
      >
        <p className="tag-note-text">{tagModalData.text}</p>
        <p className="modal-footer">
          Added on {formatDate(tagModalData.timestamp)}
        </p>
      </Modal>
    </>
  );
};

export default MainPage;
