import MainPage from "./components/MainPage";
import Sidenav from "./components/Sidenav";

function App() {
  const uid = 109;

  return (
    <div className="App" style={{ display: "flex" }}>
      <Sidenav uId={uid} />

      <MainPage uId={uid} />
    </div>
  );
}

export default App;
