import React, { useEffect, useRef, useState } from "react";
import { CloseCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
import "./chatbot.css";
import axios from "axios";
import { Modal } from "antd";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const prompt = `You are an AI assistant who helps Volunteers with their questions and is an expert in Hospice Palliative Care. These questions are addressed to a Volunteer coordinator in search of guidance and help. You will reply in place of the Volunteer coordinator if you can. If there is a question that requires unknown data, then you need to reply with "I need to check this with the coordinator and will get back to you".
Here are some Do's and Don'ts for a Volunteer to follow.
"""
DO let your genuine concern and care show.
DO allow them to express as much grief as they are feeling and are willing to share.
DO be available to listen, run errands, help with children, or whatever else seems needed at the time.
DO reassure them that they did everything they could, that the medical care their loved one received was the best, or with whatever else you know to be true and positive about the care given to one who died.
DO, in asking how they are, be specific. "The last time I talked to you, I remember..." "I've been thinking about you a lot, and wondering how things are going for you since..."

DON'T say, "It was God's will," "I know how you feel," "Time will heal."
DON'T say, "God is teaching you to trust Him and increase your faith."
DON'T say, "It could have been worse; at least your wife lived," or "At least one child made it," or "The baby was a girl and you wanted a boy," or "The baby would have been retarded."
DON'T say, "These things don't just happen; there has got to be a good reason."
DON'T avoid the grieving because you feel uncomfortable.
DON'T avoid mentioning the deceased person's name out fear of reminding the bereaved of their pain.
"""

## (SUSAN) PATIENT'S DETAILS / Life Journey
### Early Life
- Born and raised in Minneapolis in a family of 5 kids, all deceased except her sister.
- Quit school after 10th grade.
### Family
- Married at 22 and divorced at 30.
- Raised 3 children with her second husband in south Minneapolis.
- Lost her second husband last year.
### Personal Interests
- Finds comfort in hymns and scripture.
- Loves listening to 70s/80s music, especially by Elvis Presley.
- Enjoys watching old westerns on TV.
- Enjoys playing bingo.
### Challenges
- Experienced shoulder pain from a teenage accident.
- Angry at her sister for not moving in with her.
### Pets
- Has a cat named LilBits.
- Enjoys visits from Lily, a therapy pet.


You should always follow the following rules:
- Keep responses short, meaningful, and to the point. Response format should be github flavoured markdown.
- Think like a Volunteer Helper and be compassionate with your response.
- Avoid questions related to politics and celebrity.
- Always provide the NEXT one paragraph of the book with proper formatting when asked to continue reading.
- Create a bingo chart if asked for one. Make it random everytime.
- Provide a recipe if asked for.
- Suggest songs based on patient's preference if asked for.

- HYPERLINK EVERY SONG WITH THEIR YOUTUBE SEARCH UNDER THE TEXT "LISTEN HERE".`;

const ChatBot = ({ onClose }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [isSuggestionModalVisible, setIsSuggestionModalVisible] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const chatEndRef = useRef(null);

  const inputSugestions = [
    "Tell me about Susan’s life?",
    "How can I spend time with Susan Today?",
    "What type of music does Susan like?",
    "Suggest me some songs for Susan to play",
    "Suggest a game",
    "How can I talk with non verbal patients?"
  ];

  // useEffect(() => {
  //   const welcomeMessageFunction = () => {
  //     const welcome =
  //       "Hi I am a Volunteer Assistant. How can I help you today ?";
  //     setMessages([{ text: welcome, sender: "bot" }]);
  //   };

  //   welcomeMessageFunction();
  // }, []);

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (newMessage.trim() === "") return;

    const userMessage = { text: newMessage, sender: "user" };
    setMessages([...messages, userMessage]);
    setNewMessage("");

    try {
      const history = [...messages, userMessage];
      setLoading(true);
      const response = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        {
          model: "gpt-4o",
          messages: [
            { role: "system", content: prompt },
            // { role: "user", content: newMessage },
            ...history.map((msg) => ({
              role: msg.sender === "user" ? "user" : "assistant",
              content: msg.text,
            })),
          ],
          temperature: 0,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.REACT_APP_API_KEY} `,
          },
        }
      );
      const botResponse = {
        text: response.data.choices[0].message.content,
        sender: "bot",
      };
      setMessages((prevMessage) => [...prevMessage, botResponse]);
      setLoading(false);
    } catch (error) {
      console.log("Error in fetching GPT response", error);
    }
  };
  const suggestionModal = () => {
    setIsSuggestionModalVisible(true);
  };

  const handleCopyInput = (input) => {
    setNewMessage(input);
    setIsSuggestionModalVisible(false);
  };

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  return (
    <>
      <div className="chatbot-container">
        <div className="chatbot-header">
          Chatbot
          <CloseCircleOutlined onClick={onClose} />
        </div>
        <div className="chatbot-body ">
          {messages.map((message, index) => (
            <div key={index} className={`message ${message.sender}`}>
              {message.sender === "bot" ? (
                <ReactMarkdown className="markdown" remarkPlugins={[remarkGfm]}>
                  {message.text}
                </ReactMarkdown>
              ) : (
                <p>{message.text}</p>
              )}
              <div ref={chatEndRef} />
            </div>
          ))}
          {loading && (
            <div className="loading">
              <span className="loading__dot"></span>
              <span className="loading__dot"></span>
              <span className="loading__dot"></span>
            </div>
          )}
        </div>
        <div className="chatbot-footer">
          <form onSubmit={handleSendMessage}>
            <input
              type="text"
              placeholder="Type your message..."
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
            />
            <InfoCircleOutlined
              onClick={suggestionModal}
              style={{
                fontSize: "24px",
                color: "#1967d3",
                marginRight: "10px",
                textAlign: "center",
              }}
            />
            <button type="submit">Send</button>
          </form>
        </div>
      </div>
      <Modal
        title="Suggested Input"
        open={isSuggestionModalVisible}
        onCancel={() => setIsSuggestionModalVisible(false)}
        footer={null}
      >
        <ul className="suggested-questions">
          {inputSugestions.map((input, index) => (
            <li key={index}>
              {input}
              <span className="copy" onClick={() => handleCopyInput(input)}>
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="paste"
                  className="svg-inline--fa fa-paste pasteText"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M160 0c-23.7 0-44.4 12.9-55.4 32H48C21.5 32 0 53.5 0 80V400c0 26.5 21.5 48 48 48H192V176c0-44.2 35.8-80 80-80h48V80c0-26.5-21.5-48-48-48H215.4C204.4 12.9 183.7 0 160 0zM272 128c-26.5 0-48 21.5-48 48V448v16c0 26.5 21.5 48 48 48H464c26.5 0 48-21.5 48-48V243.9c0-12.7-5.1-24.9-14.1-33.9l-67.9-67.9c-9-9-21.2-14.1-33.9-14.1H320 272zM160 40a24 24 0 1 1 0 48 24 24 0 1 1 0-48z"
                  ></path>
                </svg>
              </span>
            </li>
          ))}
        </ul>
      </Modal>
    </>
  );
};

export default ChatBot;
